import { useState, useLayoutEffect } from 'react';
import { debounce } from 'lodash-es';

export default function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    const updateSize = () => setSize([window.innerWidth, window.innerHeight]);
    const debouncedUpdateSize = debounce(updateSize, 500);

    window.addEventListener('resize', debouncedUpdateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}
