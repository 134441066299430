import { useEffect, useMemo } from 'react';
import { Customer } from '@lambda/apis/src/customer/types';
import settings from '@/settings/index';
import { trackLogin, trackSignup } from '@/lib/analytics/trackCustomer';
import { Breakpoints } from '@/settings/breakpoints';
import useWindowSize from '@/lib/use-window-size';
import { logger } from '@/lib/logger';
import { useCustomerContext } from '@/lib/customer/CustomerContext';
import { GoogleResponse } from '@/lib/customer/types';

export enum SocialLoginAction {
  LOGIN = 'Login',
  SIGNUP = 'Signup',
}

export default function SocialLogin({
  action,
  onSuccess,
}: {
  action: SocialLoginAction;
  onSuccess: (customer: Customer) => void;
}) {
  const { loginWithGoogle } = useCustomerContext();

  const handleGoogleResponse = async (googleResponse: GoogleResponse) => {
    const customer = await loginWithGoogle(googleResponse);

    if (!customer) {
      logger.error('Google response error');

      return;
    }

    switch (action) {
      case SocialLoginAction.LOGIN:
        trackLogin();
        break;
      case SocialLoginAction.SIGNUP:
        trackSignup();
        break;
      default:
        break;
    }

    onSuccess(customer);
  };

  useEffect(() => {
    (window as any).handleGoogleResponse = handleGoogleResponse;

    return () => {
      (window as any).trackGoogleClick = undefined;
      (window as any).handleGoogleResponse = undefined;
    };
  }, []);

  const windowSize = useWindowSize();
  const isTablet = windowSize[0] < Breakpoints.MD;
  const isMobile = windowSize[0] < Breakpoints.SM;

  const ssoWidth = useMemo(() => {
    if (isMobile) return 304;
    if (isTablet) return 400;

    return 348;
  }, [isMobile, isTablet]);

  return (
    <>
      <div className="relative mt-1 py-3">
        <div className="absolute h-[1px] w-full bg-gray-500 xxs:h-[2px]"></div>
        <div className="absolute left-1/2 top-1/2 w-8 -translate-x-1/2 -translate-y-1/2 bg-white text-center text-xs font-bold xxs:text-sm">
          OR
        </div>
      </div>
      <div
        id="g_id_onload"
        data-client_id={settings.gsi_client_id}
        data-context="signin"
        data-ux_mode="popup"
        data-callback="handleGoogleResponse"
        data-auto_prompt="false"
      />
      <section className="flex h-[56px] justify-center py-2">
        <div
          // eslint-disable-next-line tailwindcss/no-custom-classname
          className="g_id_signin"
          data-type="standard"
          data-shape="rectangle"
          data-theme="outline"
          data-text="signin_with"
          data-size="large"
          data-logo_alignment="left"
          data-click_listener="trackGoogleClick"
          data-width={ssoWidth}
        />
      </section>
    </>
  );
}
