import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Link from 'next/link';
import Image from 'next/future/image';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { CtaButton } from '@/components/Cta/Cta';
import logoWheelleft from '@/public/images/logo_wheel_left.svg';
import logoWheelright from '@/public/images/logo_wheel_right.svg';
import i18n from '@/settings/i18n';
import settings from '@/settings/configs';
import { getShortenCountryName } from '@/lib/use-origin-country';
import AccountIcon from '@/components/accounts/AccountIcon';
import { loadGoogleSigninScript } from '@/lib/google-signin';
import SocialLogin, {
  SocialLoginAction,
} from '@/components/accounts/SocialLogin';
import { WebsiteSchema } from '@/components/homepage/WebsiteSchema';
import BellFillIcon from '@/public/icons/account/bell-fill.svg';
import OpenEyeIcon from '@/public/icons/account/eye_open.svg';
import ClosedEyeIcon from '@/public/icons/account/eye_closed.svg';
import { useCustomerContext } from '@/lib/customer/CustomerContext';
import { logger } from '@/lib/logger';
import { shouldUseCognito } from '@/components/commons/Customer/constants';
import CognitoLoginForm from '@/components/commons/Customer/CognitoLoginForm';

const t = i18n.init();
const schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email(t`Email must be a valid email`)
      .required(t`Email is a required field`),
    password: yup
      .string()
      .min(6, t`Password must be at least 6 characters`)
      .required(t`Password is a required field`),
  })
  .required();
type Form = yup.Asserts<typeof schema>;

const OrderCopy = () => (
  <div
    className="mb-4 flex items-center rounded-md bg-[#F5FFFF] p-4 text-sm text-[#497C7D] shadow-sm"
    role="alert"
  >
    <div className="mr-5 flex h-[20px] w-[20px] items-center justify-center">
      <span className="h-[20px] w-[20px]">
        <Image
          height={20}
          width={20}
          priority
          alt="Wishlist"
          src={BellFillIcon}
          className="mr-4"
        />
      </span>
    </div>
    <div className="text-left">
      Create an account or log-in to receive real-time order updates and get
      notified about exciting deals!
    </div>
  </div>
);

const DefaultLogin = () => {
  const router = useRouter();

  const form = useForm<Form>({ resolver: yupResolver(schema) });
  const { login, loading, error, customer } = useCustomerContext();
  const [passwordVisible, setPasswordVisible] = useState(false);

  let orderNotice = false;
  let redirectWithRouter = true;
  let redirectUrl = '';

  if (typeof router.query?.redirectUrl === 'string') {
    redirectUrl = router.query?.redirectUrl;
  } else if (typeof router.query?.checkout_url === 'string') {
    redirectWithRouter = false;
    redirectUrl = router.query?.checkout_url;
  } else {
    redirectUrl = '/account/orders';
    orderNotice = true;
  }

  useEffect(() => {
    if (!customer) return;
    if (redirectWithRouter) router.push(redirectUrl);
    else window.location.href = redirectUrl;
  }, [customer]);

  useEffect(() => {
    loadGoogleSigninScript();
  }, []);

  return (
    <>
      {orderNotice && <OrderCopy />}
      <h1 className="mb-2 flex flex-col items-center justify-center text-xl font-bold text-gray-700 md:mb-4 md:flex-row md:text-[1.875rem]">
        <AccountIcon />
        {t`Login to my account`}
      </h1>
      <form
        onSubmit={form.handleSubmit((input) =>
          login(input.email, input.password),
        )}
      >
        <div className="flex w-full flex-col">
          <input
            className="my-1 appearance-none rounded-sm border border-solid border-gray-500  p-3 text-gray-700 outline-1 outline-gray-700"
            placeholder={t`Email`}
            type="email"
            {...form.register('email')}
          />
          {form.formState.errors.email && (
            <div className="text-xs text-red">
              {form.formState.errors.email.message}
            </div>
          )}
        </div>
        <div className="relative flex w-full flex-col">
          <input
            className="my-1 appearance-none rounded-sm border border-solid border-gray-500  p-3 text-gray-700 outline-1 outline-gray-700"
            placeholder={t`Password`}
            type={passwordVisible ? 'text' : 'password'}
            {...form.register('password')}
          />
          <button
            className={
              'absolute right-5 mt-5 flex h-[20px] w-[20px] translate-x-0.5 cursor-pointer items-center justify-center'
            }
            onClick={() => {
              setPasswordVisible(!passwordVisible);
            }}
            type="button"
          >
            <span className="h-[20px] w-[20px]">
              <Image
                src={passwordVisible ? OpenEyeIcon : ClosedEyeIcon}
                width={20}
                height={20}
                alt="Add to Wishlist"
                className="h-full w-[20px] object-contain"
                priority
              />
            </span>
          </button>
          {form.formState.errors.password && (
            <div className="text-xs text-red">
              {form.formState.errors.password.message}
            </div>
          )}
        </div>

        <CtaButton
          type="submit"
          className="my-1 w-full !px-3 !py-2"
          onSubmit={form.handleSubmit((input) =>
            login(input.email, input.password),
          )}
          disabled={loading}
        >
          {t`Login`}
        </CtaButton>
        {error && (
          <p className="text-center text-sm text-red">
            Incorrect email or password
          </p>
        )}
      </form>
      <SocialLogin
        action={SocialLoginAction.LOGIN}
        onSuccess={() => logger.debug('Authenticated')}
      />
      <div className="mt-5 md:mt-8">
        <div className="mb-2 flex justify-center text-xs text-gray-700 md:text-[1rem]">
          {t`New Customer?`}
          <div className="ml-1 text-blue-500 duration-300 ease-in-out">
            <Link href={'/account/register'}>{t`Create your account`}</Link>
          </div>
        </div>
        <div className="flex justify-center text-xs text-gray-700 md:text-[1rem]">
          {t`Lost password?`}
          <Link href={'/account/recover'}>
            <a className="ml-1 text-blue-500 duration-300 ease-in-out">
              {t`Recover password`}
            </a>
          </Link>
        </div>
      </div>
    </>
  );
};

export default () => {
  const router = useRouter();

  return (
    <>
      <Head>
        <title>{t`Account Login - Reebelo ${{
          country: getShortenCountryName(),
        }}`}</title>
        <WebsiteSchema />
        <meta
          name="description"
          content={t`Reebelo ${{
            country: settings.country,
          }} is APAC's leading marketplace for sustainable tech with offices in ${{
            country_office_locations: settings.country_office_locations,
          }}. The positive customer experience at Reebelo ${{
            country: settings.country,
          }} is of utmost importance to us. Please check here for Reebelo ${{
            country: settings.country,
          }} Account Login.`}
        />
      </Head>
      <div className="relative bg-teal-500 py-5 md:py-14">
        <div className="reebelo-container my-0 md:my-2">
          <div className="mx-auto w-full max-w-[520px] rounded-[5px] bg-white px-6 py-5 shadow-xl md:px-20 md:py-10">
            {shouldUseCognito ? (
              <CognitoLoginForm onSuccess={() => router.push('/')} />
            ) : (
              <DefaultLogin />
            )}
          </div>
        </div>
        <div className="absolute left-0 top-0 hidden lg:block">
          <Image
            height={104}
            width={208}
            alt="logoWheelleft"
            src={logoWheelleft}
          />
        </div>
        <div className="absolute bottom-0 right-0 hidden leading-[0] lg:block">
          <Image
            height={392}
            width={241}
            alt="logoWheelright"
            src={logoWheelright}
          />
        </div>
      </div>
    </>
  );
};
